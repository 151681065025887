// External Imports
import React, { Fragment } from "react";
import { graphql } from "gatsby";
// Components
import {
  CareersAvailablePosition,
  DynamicCallToActionBanner,
  TwoColumnTitleSubtitleImage,
  ThreeColumnCards,
  DynamicCustomerReview,
  SingleColumnLayout,
  TwoColumnDynamicList,
  ThreeColumnTextImages,
  ComplexTwoColumn,
  SimpleOneColumn,
  TwoColumnTitleAndSubtitle,
  TwoColumnBlogCard,
  TwoColEmailCapture,
  DynamicCarousel,
  RecentBlogs,
  EmailCaptureFileDownload,
  DynamicMediaCardGrid,
  PricingCardGrid,
  SinglePriceCard,
  DynamicContactUsForm,
  DynamicSupportRequest,
  DynamicPartnerApply,
  TextOnlyNumberColumns,
  EmailNameCaptureForm,
} from "./DynamicComponents/exports.dynamicComponents";
import { SEO } from "../../exports/components.exports";

/**
 * @constant query for strapi
 */
export const query = graphql`
  query PageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      block
      seo {
        id
        metaDescription
        metaTitle
      }
      slug
      title
    }
  }
`;

/**
 * @function DynamicPage component
 * @param data
 * @returns the blog DynamicPage component that renders the content from strapi
 */
const DynamicPage = ({ data }: { data: any }) => {
  const { metaTitle, metaDescription } = data.strapiPage.seo;
  const { block } = data.strapiPage;

  const seo = {
    metaTitle: `Swiftcase - ${metaTitle}`,
    metaDescription,
  };

  /**
   * @function getBlockComponent switch statement to filter through the dynamic zone data from strapi
   * @returns the components that match the strapi data
   */
  const getBlockComponent = (block: any) => {
    switch (block.strapi_component) {
      case "website-components.job-listings":
        return <CareersAvailablePosition blockData={block} />;
      case "website-components.two-column-title-subtitle-image":
        return <TwoColumnTitleSubtitleImage blockData={block} />;
      case "website-components.three-column-cards":
        return <ThreeColumnCards blockData={block} />;
      case "website-components.customer-review":
        return <DynamicCustomerReview blockData={block} />;
      case "website-components.single-column-layout":
        return <SingleColumnLayout blockData={block} />;
      case "website-components.two-column-text-list":
        return <TwoColumnDynamicList blockData={block} />;
      case "website-components.three-column-text-images":
        return <ThreeColumnTextImages blockData={block} />;
      case "website-components.complex-two-column":
        return <ComplexTwoColumn blockData={block} />;
      case "website-components.simple-one-column":
        return <SimpleOneColumn blockData={block} />;
      case "website-components.two-column-title-and-subtitle":
        return <TwoColumnTitleAndSubtitle blockData={block} />;
      case "website-components.two-column-blog-card":
        return <TwoColumnBlogCard blockData={block} />;
      case "website-components.two-col-email-capture":
        return <TwoColEmailCapture blockData={block} />;
      case "website-components.carousel":
        return <DynamicCarousel blockData={block} />;
      case "website-components.recent-blogs":
        return <RecentBlogs blockData={block} />;
      case "website-components.email-capture-file-download":
        return <EmailCaptureFileDownload blockData={block} />;
      case "website-components.card-grid":
        return <DynamicMediaCardGrid blockData={block} />;
      case "website-components.pricing-card-grid":
        return <PricingCardGrid blockData={block} />;
      case "website-components.single-price-card":
        return <SinglePriceCard blockData={block} />;
      case "sections.contact-us-form":
        return <DynamicContactUsForm blockData={block} />;
      case "sections.support-form":
        return <DynamicSupportRequest blockData={block} />;
      case "sections.partner-enquiry-form":
        return <DynamicPartnerApply blockData={block} />;
      case "website-components.only-text-vary-columns":
        return <TextOnlyNumberColumns blockData={block} />;
      case "website-components.email-and-name-capture-form":
        return <EmailNameCaptureForm blockData={block} />;
      default:
        return null;
    }
  };

  const getCallToAction = () => {
    let callToAction = block.filter(
      (block: any) =>
        block.strapi_component === "website-components.call-to-action-banner"
    );
    if (callToAction) {
      return callToAction.map((block: any, index: number) => (
        <DynamicCallToActionBanner
          key={`${block.id}-${index}`}
          blockData={block}
        />
      ));
    }

    return;
  };

  return (
    <Fragment>
      <SEO seo={seo} />
      {getCallToAction()}
      {block &&
        block.map((block: any, index: number) => {
          return (
            <Fragment key={`${block.id}-${index}`}>
              {getBlockComponent(block)}
            </Fragment>
          );
        })}
    </Fragment>
  );
};

export default DynamicPage;
